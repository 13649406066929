<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MixPanelInit',
  watch: {
    'organization.id'(newValue) {
      if (newValue) {
        // Setup account and user.
        this.setupMixpanelAccount()
        this.setupMixpanelUser(this.organization)
      }
    },
    'currentUser.uid'(newValue) {
      if (newValue) {
        this.setupMixpanelUser()
      }
    }
  },
  mounted() {
    if (this.currentUser && this.currentUser.uid) {
      this.setupMixpanelUser()
    }
  },

  render() {
    // This component does not render anything.
    return null
  },
  methods: {
    setupMixpanelUser(organization) {
      // Setup user if not zoom app
      if (this.currentUser && this.currentUser.uid && !this.currentUser.impersonated) {
        const userProperties = {
          name: this.currentUser.displayName,
          email: this.currentUser.email
        }

        if (organization && organization.id) {
          userProperties.organization = organization.id
        }

        if (organization && organization.name) {
          userProperties.organizationName = organization.name
        }

        if (organization && organization.created && (organization.created.seconds || organization.created._seconds)) {
          const seconds = organization.created.seconds || organization.created._seconds

          const organizationCreatedEpoc = Number(seconds * 1000)
          if (organizationCreatedEpoc) {
            const organizationCreatedAt = new Date(organizationCreatedEpoc).toISOString()
            userProperties.organizationCreatedAt = organizationCreatedAt
          }
        }

        this.$mixpanel.peopleSet(this.currentUser.uid, userProperties)
      }
    },
    setupMixpanelAccount() {
      // Setup user if not zoom app
      if (this.currentUser && this.currentUser.uid && !this.currentUser.impersonated) {
        let organizationCreatedAt = null

        if (history.organization && this.organization.created && (this.organization.created.seconds || this.organization.created._seconds)) {
          const seconds = this.organization.created.seconds || this.organization.created._seconds

          const organizationCreatedEpoc = Number(seconds * 1000)
          if (organizationCreatedEpoc) {
            organizationCreatedAt = new Date(organizationCreatedEpoc).toISOString()
          }
        }

        const orgName = this.organization && this.organization.name ? this.organization.name.trim() : ''

        const organizationProperties = {
          organizationCreatedAt,
          organizationName: orgName,
          $name: orgName,
          paymentStatus: this.organization.paymentStatus
        }

        this.$mixpanel.setGroup('organization', this.organization.id, organizationProperties)
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'activeUser',
      organization: 'company'
    })
  }
}
</script>
