// plugins/mixpanel.client.js
/* global mixpanel */

const token = process.env.VUE_APP_MIXPANEL_TOKEN
import store from '@/store/store.js'

if (!token) {
  console.error('Mixpanel token is not defined!')
}

mixpanel.init(token, {
  ignore_dnt: true,
  track_pageview: false // keep auto-tracking off
})

export default {
  trackPageView(to, from) {
    const currentUser = store.getters.activeUser
    if (currentUser && currentUser.uid) {
      // Only call identify if the current distinct_id is different.
      if (mixpanel.get_distinct_id() !== currentUser.uid) {
        mixpanel.identify(currentUser.uid)
      }
    }
    const pageProps = {
      page: to.fullPath,
      title: document.title,
      referrer: from.fullPath,
      ...(currentUser && currentUser.uid ? { distinct_id: currentUser.uid } : {})
    }

    mixpanel.track_pageview(pageProps)
  }
}
