import { mapGetters } from 'vuex'
// Events.
export const AnalyticsEvents = {
  REGISTER_ENTERED_NAME_AND_EMAIL: 'REGISTER_ENTERED_NAME_AND_EMAIL',
  REGISTER_REGISTERED_USER: 'REGISTER_REGISTERED_USER',
  REGISTER_ACCOUNT_CREATED: 'REGISTER_ACCOUNT_CREATED',
  REGISTER_ACCOUNT_SUCCESS_PAGE: 'REGISTER_ACCOUNT_SUCCESS_PAGE',
  REGISTER_GO_TO_YOUR_ACCOUNT: 'REGISTER_GO_TO_YOUR_ACCOUNT',
  REGISTER_SKIP_ACCOUNT_INFO_STEP: 'REGISTER_SKIP_ACCOUNT_INFO_STEP',
  REGISTER_SKIP_VEGA_ACTIVATION_STEP: 'REGISTER_SKIP_VEGA_ACTIVATION_STEP',

  QUICK_GUIDE_STARTED: 'QUICK_GUIDE_STARTED',
  QUICK_GUIDE_CREATE_DIALOG: 'QUICK_GUIDE_CREATE_DIALOG',
  QUICK_GUIDE_CLOSED: 'QUICK_GUIDE_CLOSED',

  CLICKS_ADD_WEB_CONNECT: 'CLICKS_ADD_WEB_CONNECT',
  CLICKS_EDIT_WIDGET: 'CLICKS_EDIT_WIDGET',
  CLICKS_WIDGET_SETTINGS: 'CLICKS_WIDGET_SETTINGS',
  CLICKS_DELETE_WIDGET: 'CLICKS_DELETE_WIDGET',
  CLICKS_WIDGET_AB_TESTING: 'CLICKS_WIDGET_AB_TESTING',
  CLICKS_WIDGET_ENABLE_DISABLE: 'CLICKS_WIDGET_ENABLE_DISABLE',
  CLICKS_COPY_WIDGET: 'CLICKS_COPY_WIDGET',

  CLICKS_REGISTER_GOOGLE: 'CLICKS_REGISTER_GOOGLE',
  CLICKS_REGISTER_LINKEDIN: 'CLICKS_REGISTER_LINKEDIN',
  CLICKS_SIGNIN_GOOGLE: 'CLICKS_SIGNIN_GOOGLE',
  CLICKS_SIGNIN_LINKEDIN: 'CLICKS_SIGNIN_LINKEDIN',
  CLICKS_LOGIN_BUTTON: 'CLICKS_LOGIN_BUTTON',
  CLICKS_RESET_PASSWORD: 'CLICKS_RESET_PASSWORD',

  WEB_CONNECT_PREPARE_WEBSITE: 'WEB_CONNECT_PREPARE_WEBSITE',
  WEB_CONNECT_DISPLAY_OPTIONS: 'WEB_CONNECT_DISPLAY_OPTIONS',
  WEB_CONNECT_TEXT_COLORS: 'WEB_CONNECT_TEXT_COLORS',
  WEB_CONNECT_REQUEST_OPTIONS: 'WEB_CONNECT_REQUEST_OPTIONS',
  WEB_CONNECT_SESSION_END_SCREEN: 'WEB_CONNECT_SESSION_END_SCREEN',
  WEB_CONNECT_CONTACT_FORM: 'WEB_CONNECT_CONTACT_FORM'
}

export const analyticsMixin = {
  methods: {
    async logAnalyticsEvent(eventType, properties) {
      //console.log(`\x1b[31m${eventType}\x1b[0m`)
      const insightProperties = JSON.parse(JSON.stringify(properties || {}))
      if (this.currentUser && this.currentUser.uid) {
        insightProperties.distinct_id = this.currentUser.uid
      }
      if (this.currentUser && this.currentUser.company) {
        insightProperties.company = this.currentUser.company
      }

      if (this.company && this.company.name) {
        insightProperties.companyName = this.company.name
      }
      this.$mixpanel.track(eventType, insightProperties)
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      currentUser: 'activeUser'
    })
  }
}
