import axios from 'axios'
/* eslint-disable no-unused-vars */

// Helper functions for localStorage persistence
function loadQueueFromStorage() {
  const stored = localStorage.getItem('welo-mp-eq')
  return stored ? JSON.parse(stored) : []
}

function saveQueueToStorage(queue) {
  localStorage.setItem('welo-mp-eq', JSON.stringify(queue))
}

// We'll store events in this array before flushing.
let eventQueue = loadQueueFromStorage()
let flushIntervalId = null

// Helper function to flush the queue.
async function flushQueue(serverUrl) {
  if (!eventQueue.length) {
    return // Nothing to send
  }

  const eventsToSend = [...eventQueue]
  eventQueue = []

  // Save the updated (empty) queue to localStorage.
  saveQueueToStorage(eventQueue)

  try {
    // Make a single POST request with all queued events.
    await axios.post(`${serverUrl}/mixpanel-trackbatch`, {
      events: eventsToSend
    })
  } catch (err) {
    /* eslint-disable no-console */
    console.error('Batch send error:', err)
  }
}

const MixpanelPlugin = {
  install(Vue, options = {}) {
    const serverUrl = options.serverUrl

    // Start a periodic flush if not already started
    if (!flushIntervalId) {
      flushIntervalId = setInterval(() => {
        flushQueue(serverUrl)
      }, 30000) // Flush every 5 seconds (adjust as needed)
    }

    Vue.prototype.$mixpanel = {
      /**
       * Update people (user profile) properties.
       * Expects a payload: { properties }
       */
      async peopleSet(userId, properties = {}) {
        try {
          // Convert camelCase keys to snake_case and filter out null/undefined values.
          const camelToSnake = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
          const filteredProperties = Object.fromEntries(
            Object.entries(properties)
              .filter(([_, value]) => value !== undefined && value !== null)
              .map(([key, value]) => [camelToSnake(key), value])
          )
          // If filteredProperties includes 'email', add '$email' with the same value.
          if ('email' in filteredProperties) {
            filteredProperties['$email'] = filteredProperties.email
          }
          await axios.post(`${serverUrl}/mixpanel-peopleSet`, {
            userId,
            properties: filteredProperties
          })
        } catch (err) {
          /* eslint-disable no-console */
          console.error('Mixpanel peopleSet error:', err)
        }
      },

      /**
       * Set group analytics.
       * Expects a payload: { groupKey, groupId, properties }
       */
      async setGroup(groupKey, groupId, properties = {}) {
        try {
          // Convert camelCase keys to snake_case and filter out null/undefined values.
          const camelToSnake = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
          const filteredProperties = Object.fromEntries(
            Object.entries(properties)
              .filter(([_, value]) => value !== undefined && value !== null)
              .map(([key, value]) => [camelToSnake(key), value])
          )
          // Use the throttled setGroup function.
          try {
            await axios.post(`${serverUrl}/mixpanel-setGroup`, {
              groupKey,
              groupId,
              properties: filteredProperties
            })
          } catch (err) {
            /* eslint-disable no-console */
            console.error('Mixpanel setGroup error:', err)
          }
        } catch (err) {
          /* eslint-disable no-console */
          console.error('Mixpanel setGroup error:', err)
        }
      },

      /**
       * Track an event (batched).
       * Expects a payload: { eventName, properties }
       */
      async track(eventName, properties = {}) {
        // Convert camelCase keys to snake_case and filter out null/undefined values.
        const camelToSnake = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
        const filteredProperties = Object.fromEntries(
          Object.entries(properties)
            .filter(([_, value]) => value !== undefined && value !== null)
            .map(([key, value]) => [camelToSnake(key), value])
        )

        // Add a time property as a Unix timestamp (seconds)
        const event = {
          eventName,
          properties: {
            ...filteredProperties,
            time: Math.floor(Date.now() / 1000)
          }
        }

        // Instead of sending immediately, push to the queue.
        eventQueue.push(event)

        // Save the updated queue to localStorage.
        saveQueueToStorage(eventQueue)
      },

      /**
       * Manually flush the event queue on demand.
       */
      async flushQueueNow() {
        await flushQueue(serverUrl)
      }
    }
  }
}

export default MixpanelPlugin
