/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = function (query) {
    return window.matchMedia(query).matches
  }

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser: null,
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  is_touch_device: is_touch_device(),
  mainLayoutType: themeConfig.mainLayoutType || 'vertical',
  navbarSearchAndPinList,
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: 'default',
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  starredPages: navbarSearchAndPinList['pages'].data.filter((page) => page.is_bookmarked),
  theme: themeConfig.theme || 'light',
  themePrimaryColor: themeConfig.primary,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
  visitors: {
    list: [],
    incoming: [],
    static: [],
    b2bEnabled: false,
    showOnlineVisitors: false,
    dateRange: {
      startDate: null,
      endDate: null
    },
    dateRangeIncoming: {
      startDate: null,
      endDate: null
    },
    visitorsLastUpdated: null
  },
  notifications: [],
  audioAccess: false,

  all_dialogs: [],
  dialogs: [],
  abtests: [],
  companyUsers: [],
  meetings: [],
  version: null,
  campaigns: [],
  users: [],
  leads: [],
  assignment_rules: [],
  user_groups: [],
  // Stores chat message files
  pickedFiles: [],
  files: [],
  invoices: [],
  subscription: null,
  subscription_plans: [],
  company: null,
  refresh: {
    invoices: false,
    subscription: false,
    plans: false
  },
  call_center_settings: [],
  call_center_user_worktime: null,
  agency_settings: [],
  visitor: null,
  visitorId: null,
  visitorIsTyping: false,
  // company call center
  onlineUsers: [],
  callCenterAgentsToBeNotified: [],
  banners: [],
  swiperOptions: null,
  dialog: null,
  campaignWidget: null,
  campaignId: null,
  isLoadingRegisterFunnel: false,
  lockedUI: false,
  subscriptionCancelled: false,
  currency: {
    text: 'USD',
    symbol: '$'
  },
  chatVisitors: [],
  appSumoSubscriptionRefunded: false,
  appSumoSubscriptionDeleteUsers: false,
  widgetsFilterForVisitor: [],
  widgetsAssigned: [],
  widgetsAssignedKey: Math.random().toString(36).substring(2, 15),
  hasFilesizeError: false,
  hasUserInteractedWithDOM: false,
  showRatingScreen: false,
  apiKeys: null,
  audioOutputDevices: [],
  showChangePlanUI: false,
  chosenPlan: null,
  accountVatNumber: null,
  appliedPromoCode: null,
  selectedPaymentMethod: null,
  newUidFlowStep: 1,
  showPaymentFailedUI: false,
  isSearchVisitorsOn: false,
  isSearchGptChatsOn: false,
  searchVisitors: [],
  selectedVegaWidgetId: -1,
  selectedVegaWidgetPopupId: null,
  selectedVegaWidget: null,
  selectedVegaWidgetPopup: null,
  vegaPopups: [],
  isVegaPopupEnable: true,
  vegaPopupThreshold: [],
  vegaPopupName: '',
  vegaPopupText: '',
  vegaPopupMessage: '',
  vegaLockingTime: 5,
  hidePopupAfterSeconds: 0,
  vegaPopupPosition: 'centered',
  isVegaPopupWithoutImage: false,
  isVegaPopupRequestOptionsDisabled: false,
  showMollieVega: false,
  vegaHasDailyLimit: false,
  vegaDailyLimit: '20.00',
  vegaCurrentVisitorsForTheDay: 0,
  accountList: [],
  selectedAccount: null,
  selectedAccountApiKeys: null,
  gptChats: [],
  selectedBillingCountry: null,
  searchGptChats: []
}

export default state
