/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
/* eslint-disable no-console */

import Vue from 'vue'
import Router from 'vue-router'
//import * as Sentry from '@sentry/vue'
import store from '@/store/store.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/analytics'

import { Device } from '@capacitor/device'
import { Browser } from '@capacitor/browser'
import mpc from '@/plugins/mixpanel.client.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        { path: '/', redirect: '/dashboard' },
        {
          path: '/dashboard',
          name: 'home',
          component: () => import('./views/home/Home.vue'),
          meta: {
            authRequired: true
          }
        },
        {
          path: '/incoming-requests',
          name: 'incoming-requests',
          component: () => import('./views/incoming/Incoming.vue'),
          meta: {
            //pageTitle: 'routes.incomingRequests',
            rule: 'editor',
            authRequired: true,
            disallowedRoles: ['view-only', 'vega-analyze']
          }
        },
        {
          path: '/qr-connect',
          name: 'campaigns',
          component: () => import('./views/campaigns/Campaign.vue'),
          meta: {
            pageTitle: 'routes.campaigns',
            rule: 'editor',
            authRequired: true,
            disallowedRoles: ['view-only']
          }
        },
        {
          path: '/translations',
          name: 'translations',
          component: () => import('./views/translations/Translations.vue'),
          meta: {
            pageTitle: 'vue.languages',
            authRequired: true,
            disallowedRoles: ['agent', 'supervisor', 'view-only']
          }
        },
        {
          path: '/qr-connect/:id',
          name: 'campaigns-item',
          component: () => import('./views/campaigns/ConfigureCampaign.vue'),
          meta: {
            pageTitle: 'vue.createQRConnect',
            rule: 'editor',
            authRequired: true,
            disallowedRoles: ['view-only']
          }
        },
        {
          path: '/visitors',
          name: 'visitors',
          component: () => import('./views/VisitorsList.vue'),
          meta: {
            pageTitle: 'routes.visitors',
            rule: 'editor',
            authRequired: true,
            disallowedRoles: ['view-only']
          }
        },
        {
          path: '/payment',
          name: 'payment',
          component: () => import('./views/Payment.vue'),
          beforeEnter: async (to, from, next) => {
            try {
              const { platform } = await Device.getInfo()

              if (['ios', 'android'].includes(platform)) {
                await Browser.open({ url: 'https://www.letsconnect.at/pricing' })
              } else {
                return next()
              }
            } catch (err) {
              console.log(err)

              return next()
            }
          },
          meta: {
            pageTitle: 'routes.payment',
            rule: 'editor',
            authRequired: true,
            disallowedRoles: ['agent', 'supervisor', 'view-only']
          }
        },
        {
          path: '/payment/success',
          name: 'payment-successfull',
          component: () => import('@/views/Payment.vue'),
          meta: {
            pageTitle: 'routes.payment',
            rule: 'editor',
            authRequired: true,
            disallowedRoles: ['agent', 'supervisor', 'view-only']
          }
        },
        {
          path: '/processing-payment',
          name: '',
          component: () => import('@/views/pages/ProcessingPayment.vue'),
          meta: {
            pageTitle: 'routes.payment',
            rule: 'editor',
            authRequired: true,
            disallowedRoles: ['agent', 'supervisor', 'view-only']
          }
        },
        {
          path: '/appsumo-billing',
          name: 'appsumo-billing',
          component: () => import('./views/pages/appsumo/MyPlan.vue'),
          meta: {
            pageTitle: 'routes.appsumoMyPlan',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/web-connect',
          name: 'dialog',
          component: () => import('./views/EmbedList.vue'),
          meta: {
            pageTitle: 'routes.dialog',
            rule: 'editor',
            authRequired: true,
            disallowedRoles: ['agent', 'view-only']
          }
        },
        {
          path: '/vega',
          name: 'vega',
          component: () => import('./views/vega/Vega.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/vega-measurement',
          name: 'vega-measurement',
          component: () => import('./views/vega/VegaMeasurement.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/gpt-connect',
          name: 'gpt-connect',
          component: () => import('./views/gpt-connect/GptConnect.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/gpt-connect/:id',
          name: 'gpt-connect-test',
          component: () => import('./views/gpt-connect/GptConnect.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/account-created/success',
          name: 'dialog-after-successfull-registration',
          component: () => import('./views/EmbedList.vue'),
          meta: {
            pageTitle: 'routes.dialog',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/web-connect/:id',
          name: 'dialog-item',
          component: () => import('./views/pages/dialog/ConfigureDialog.vue'),
          meta: {
            pageTitle: 'routes.createYourDialog',
            rule: 'editor',
            authRequired: true,
            disallowedRoles: ['agent', 'view-only']
          }
        },
        {
          path: '/web-connect/settings/:id',
          name: 'dialog-item-settings',
          component: () => import('./views/pages/dialog/DialogSettings.vue'),
          meta: {
            authRequired: true,
            disallowedRoles: ['agent', 'view-only']
          }
        },
        {
          path: '/embed-code/:id',
          name: 'embed-code-item',
          component: () => import('./views/EmbedItem.vue'),
          meta: {
            pageTitle: 'routes.embedCode',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/company-setup',
          name: 'company-setup',
          component: () => import('./views/CompanySetup.vue'),
          meta: {
            authRequired: true,
            disallowedRoles: ['agent', 'supervisor', 'view-only']
          }
        },
        {
          path: '/lead-overview',
          name: 'lead-overview',
          component: () => import('./views/lead-overview/LeadOverview.vue'),
          meta: {
            pageTitle: 'routes.leadoverview',
            authRequired: true,
            disallowedRoles: ['view-only']
          }
        },
        {
          path: '/ab-testing/:id',
          name: 'ab-testing',
          component: () => import('./views/ab-testing/ABTesting.vue'),
          meta: {
            pageTitle: 'routes.abtesting',
            authRequired: true,
            disallowedRoles: ['view-only']
          }
        },
        {
          path: '/companies',
          name: 'companies',
          component: () => import('./views/pages/superadmin/Companies.vue'),
          meta: {
            pageTitle: 'routes.companies',
            authRequired: true,
            isSuperAdmin: true,
            disallowedRoles: ['view-only']
          }
        },
        {
          path: '/accounts',
          name: 'accounts',
          component: () => import('./views/pages/sales/Accounts.vue'),
          meta: {
            pageTitle: 'routes.accounts',
            authRequired: true,
            isSuperAdmin: true,
            disallowedRoles: ['agent', 'supervisor', 'view-only']
          }
        },
        {
          path: '/promo-codes',
          name: 'promocodes',
          component: () => import('./views/pages/superadmin/promocodes/List.vue'),
          meta: {
            pageTitle: 'routes.promoCodes',
            authRequired: true,
            isSuperAdmin: true,
            disallowedRoles: ['view-only']
          }
        },
        {
          path: '/in-app-messaging',
          name: 'in-app-messaging',
          component: () => import('./views/InAppMessaging.vue'),
          meta: {
            pageTitle: 'routes.inAppMessaging',
            authRequired: true,
            isSuperAdmin: true
          }
        },
        {
          path: '/invoices',
          name: 'invoices',
          component: () => import('./views/Invoices.vue'),
          meta: {
            pageTitle: 'routes.invoices',
            authRequired: true,
            isSuperAdminOrFinancial: true,
            disallowedRoles: ['view-only']
          }
        },
        {
          path: '/online-meeting',
          name: 'online-meeting',
          component: () => import('./views/meeting/OnlineMeeting.vue'),
          meta: {
            pageTitle: 'routes.onlineMeetings',
            rule: 'editor',
            authRequired: true,
            disallowedRoles: ['view-only']
          }
        },
        {
          path: '/user-settings',
          name: 'user-settings',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'routes.settings',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/call-center-dialogs/:id',
          name: 'call-center-dialogs',
          component: () => import('./views/pages/company/call-center/CallCenterEmbedList.vue'),
          meta: {
            pageTitle: 'routes.createYourDialog',
            rule: 'editor',
            authRequired: true,
            disallowedRoles: ['view-only']
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/c/:id',
          name: 'campaign-visitor-redirects'
        },
        {
          path: '/e/:id',
          name: 'page-manage-meeting',
          component: () => import('@/views/pages/meeting/MeetingManage'),
          meta: {
            authorize: []
          }
        },
        {
          path: '/v/:id',
          name: 'site-visitor-redirects'
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/LoginFunnel.vue')
        },
        {
          path: '/appsumo-activate',
          name: 'appsumo-activate',
          component: () => import('@/views/pages/appsumo/Activate.vue')
        },
        {
          path: '/activate-user',
          name: 'activate-user',
          component: () => import('@/views/pages/appsumo/Activate.vue')
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPasswordFunnel.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue')
        },
        {
          path: '/set-password',
          name: 'page-set-password',
          component: () => import('@/views/pages/password/UserSetPassword.vue')
        },
        {
          path: '/user-onboarding',
          name: 'page-user-onboarding',
          component: () => import('@/views/pages/password/UserInvitation.vue')
        },
        {
          path: '/pages/redirect',
          name: 'page-redirect',
          component: () => import('@/views/pages/Redirect.vue')
        },
        {
          path: '/pages/meeting',
          name: 'page-meeting',
          component: () => import('@/views/pages/meeting/MeetingWaitingRoom.vue')
        },
        {
          path: '/pages/qr-connect',
          name: 'page-campaign',
          component: () => import('@/views/pages/campaign/CampaignWaitingRoom.vue')
        },
        {
          path: '/verify-sync-calendar',
          name: 'page-verify-sync-calendar',
          component: () => import('@/views/pages/VerifySyncCalendar.vue'),
          meta: {
            authRequired: true
          }
        },
        {
          path: '/online-meeting-room/:id',
          name: 'page-online-meeting-room',
          component: () => import('@/views/meeting/OnlineMeetingRoom.vue'),
          meta: {
            authorize: []
          }
        },
        {
          path: '/book-meeting/:id',
          name: 'page-book-meeting',
          component: () => import('@/views/pages/meeting/MeetingIframe'),
          meta: {
            authorize: []
          }
        },
        {
          path: '/shopify',
          name: 'shopify',
          component: () => import('@/views/pages/shopify/Shopify.vue'),
          meta: {
            authorize: []
          }
        },
        {
          path: '/shopify-callback',
          name: 'shopify-callback',
          component: () => import('@/views/pages/shopify/ShopifyCallback.vue'),
          meta: {
            authorize: []
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach((to, from) => {
  // Track page view using your Mixpanel client plugin
  mpc.trackPageView(to, from)
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// Redirect Shortened URLs
router.beforeEach(async (to, from, next) => {
  if (to.name === 'campaign-visitor-redirects' || to.name === 'site-visitor-redirects') {
    const urlRef = await firebase.firestore().collection('shortened-urls').doc(to.params.id).get()
    if (urlRef && urlRef.data() && urlRef.data().url) {
      const shortenedURL = urlRef.data().url
      return next({ path: shortenedURL })
    } else {
      return next({ name: 'page-error-404' })
    }
  } else next()
})

router.beforeEach(async (to, from, next) => {
  try {
    const { authorize } = to.meta
    const requiresAuth = to.matched.some((record) => record.meta.authRequired)

    let user = null
    const ui = store.state && store.state.AppActiveUser && Object.keys(store.state.AppActiveUser).length > 0 ? store.state.AppActiveUser : null

    if (ui && ui.companyInfo && ui.company) {
      user = ui
    } else {
      user = await firebase.getCurrentUser()
    }

    if (ui || user) {
      const requiresSuperAdmin = to.matched.some((record) => record.meta.isSuperAdmin)
      const requiresSuperAdminOrFinancial = to.matched.some((record) => record.meta.isSuperAdminOrFinancial)

      const disallowedRoles = to.matched.some((record) => record.meta.disallowedRoles)

      if (requiresSuperAdmin) {
        if (ui && ui.superadmin) {
          return next()
        } else {
          return next({ name: 'page-not-authorized', query: { to: to.path } })
        }
      }

      if (requiresSuperAdminOrFinancial) {
        if (ui && (ui.superadmin || ui.role === 'financial')) {
          return next()
        } else {
          return next({ name: 'page-not-authorized', query: { to: to.path } })
        }
      }

      if (disallowedRoles) {
        const ui = store.state && store.state.AppActiveUser && Object.keys(store.state.AppActiveUser).length > 0 ? store.state.AppActiveUser : null
        if (ui && !to.meta.disallowedRoles.includes(ui.role)) {
          return next()
        } else {
          return next({ name: 'page-not-authorized', query: { to: to.path } })
        }
      }

      if (user) {
        if (user && (!user.companyInfo || !user.companyInfo.IS_LOTTERY_WINNER)) {
          /* NEW FLOW: TRIAL USERS */
          /* IN NEW FLOW, there is no Step 4 except for Lottery Winners. Accounts by default have paymentStatus 'on-trial' */
          return next()
        } else {
          const isUnfinishedRegisterFunnel =
            user.companyInfo &&
            user.companyInfo.created &&
            (!user.companyInfo.paymentStatus || user.companyInfo.paymentStatus === 'on-trial') &&
            !user.companyInfo.isFirstPaymentCreated &&
            !user.companyInfo.currentInvoiceId &&
            !user.companyInfo.subscriptionId &&
            !user.companyInfo.shopifyStoreId &&
            !user.companyInfo.appsumoUUID &&
            user.companyInfo.planType !== 'partner' &&
            to.name !== 'dialog-after-successfull-registration'

          if (isUnfinishedRegisterFunnel) {
            const shouldRedirecteFromAuthFormSteps =
              to.name !== 'page-register' || (to.name === 'page-register' && (!to.query.step || Number(to.query.step) < 3))

            if (shouldRedirecteFromAuthFormSteps) {
              const locale = navigator.language.slice(0, 2)
              next({ name: 'page-register', query: { ...to.query, step: 3, lang: locale } })
            } else {
              return next()
            }
          } else if (['page-register', 'page-lottery', 'page-login', 'page-forgot-password'].includes(to.name)) {
            if (to.query && to.query.userId && to.query.emailVerificationCode && to.query.email) {
              return next()
            } else {
              next({ name: 'incoming-requests' })
            }
          } else {
            return next()
          }
        }
      } else if (authorize) {
        return next()
      } else if (!requiresAuth && to.name !== 'page-login') {
        return next({ name: 'page-login' })
      } else {
        return next()
      }
    } else if (requiresAuth) {
      return next({ name: 'page-login' })
    } else {
      return next()
    }
  } catch (e) {
    console.error(e)
  }
})

firebase.getCurrentUser = async () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        firebase.analytics().setUserId(user.uid)
        let idTokenResult = await user.getIdTokenResult()
        user.company = idTokenResult && idTokenResult.claims && idTokenResult.claims.company ? idTokenResult.claims.company : null
        if (!user.company) {
          idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
          user.company = idTokenResult && idTokenResult.claims && idTokenResult.claims.company ? idTokenResult.claims.company : null
        }
        if (idTokenResult && idTokenResult.claims) {
          const userInfo = {
            impersonated: typeof idTokenResult.claims.impersonated === 'boolean' ? idTokenResult.claims.impersonated : false
          }
          if (idTokenResult.claims.ipname) {
            userInfo.ipname = idTokenResult.claims.ipname
          }
          if (idTokenResult.claims.ipuid) {
            userInfo.ipuid = idTokenResult.claims.ipuid
          }
          if (idTokenResult.claims.role) {
            userInfo.role = idTokenResult.claims.role
          }

          await store.commit('UPDATE_USER_INFO', userInfo)
        }
        if (!user.displayName) {
          await store.dispatch('auth/mergeUser', user)
        }

        if (idTokenResult && idTokenResult.claims && ['admin', 'agent'].includes(idTokenResult.claims.role)) {
          firebase.setAgentOnline(user)
        } else {
          firebase.setAgentOffline(user)
        }

        await store.dispatch('auth/identifyUser', user)
        if (idTokenResult && idTokenResult.claims) {
          if (idTokenResult.claims.role && idTokenResult.claims.role === 'financial') {
            router.push({ name: 'invoices' })
          }
        }
      }
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

firebase.setAgentOnline = async (user) => {
  const ref = firebase.database().ref(`/users/${user.company}/${user.uid}`)
  const isOfflineForDatabase = {
    state: 'offline',
    displayName: user.displayName,
    last_changed: firebase.database.ServerValue.TIMESTAMP
  }

  const isOnlineForDatabase = {
    state: 'online',
    displayName: user.displayName,
    last_changed: firebase.database.ServerValue.TIMESTAMP
  }
  firebase
    .database()
    .ref('.info/connected')
    .on('value', function (snapshot) {
      if (snapshot.val() === false) {
        return
      }
      ref
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function () {
          ref.update(isOnlineForDatabase)
        })
    })
}

firebase.setAgentOffline = async (user) => {
  const ref = firebase.database().ref(`/users/${user.company}/${user.uid}`)
  const isOfflineForDatabase = {
    state: 'offline',
    displayName: user.displayName,
    last_changed: firebase.database.ServerValue.TIMESTAMP
  }
  firebase
    .database()
    .ref('.info/connected')
    .on('value', function (snapshot) {
      if (snapshot.val() === false) {
        return
      }
      ref
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function () {
          ref.update(isOfflineForDatabase)
        })
    })
}

// let _environment = ''
// //let _tracingOrigins = []

// switch (process.env.NODE_ENV) {
//   case 'production':
//     _environment = 'production'
//     /// _tracingOrigins = ['app.letsconnect.at', 'app.pathadvice.at', 'app.pathadvice.ai', /^\//]
//     break

//   case 'pathadvice-stage':
//     _environment = 'stage'
//     //_tracingOrigins = ['localhost', 'pathadvice-stage.web.app', 'pathadvice-stage.pathadvice.ai', /^\//]
//     break

//   case 'pathadvice-beta':
//     _environment = 'stage'
//     //_tracingOrigins = ['localhost', 'pathadvice-stage.web.app', 'pathadvice-stage.pathadvice.ai', /^\//]
//     break

//   default:
//     _environment = 'stage'
//     // _tracingOrigins = ['localhost', 'pathadvice-stage.web.app', 'pathadvice-stage.pathadvice.ai', /^\//]
//     break
// }
// Sentry.init({
//   Vue,
//   dsn: 'https://427b863d791876eabe8d1a358281c6f1@sentry.letsconnect.at/10',
//   environment: _environment,
//   integrations: [Sentry.browserTracingIntegration({ router })],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for tracing.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.3,

//   // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/app.letsconnect\.at/, /^https:\/\/pathadvice-stage.web\.app/],
//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0
// })

export default router
