var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.vueAppClasses, attrs: { id: "app" } },
    [
      _c("MixPanelInit"),
      _vm.showChangePlanUI ? _c("ChoosePricingPlan") : _vm._e(),
      _c("div", { attrs: { id: "pa-overlay" } }),
      _vm.lockedUI && _vm.showLockedUI ? _c("UILockedStatus") : _vm._e(),
      _c("div", {
        staticStyle: { position: "relative", "z-index": "54000" },
        attrs: { id: "portal-target" },
      }),
      _c("div", {
        staticStyle: {
          position: "fixed",
          "z-index": "54000",
          top: "50%",
          left: "100%",
        },
        attrs: { id: "connected-agent-info" },
      }),
      _c("audio", {
        attrs: {
          id: "pa-audio-access",
          src: require("@/assets/audio/nothing.wav"),
        },
      }),
      _c("router-view", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showChangePlanUI,
            expression: "!showChangePlanUI",
          },
        ],
        key: _vm.appKey,
        on: { setAppClasses: _vm.setAppClasses },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }